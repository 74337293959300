import { AfterContentInit, Directive, ElementRef, inject, Input } from '@angular/core';

@Directive({
	selector: '[slAutoFocus]',
	standalone: true
})
export class AutofocusDirective implements AfterContentInit {
	@Input() readonly slAutoFocus: boolean;
	readonly #el = inject(ElementRef);

	ngAfterContentInit(): void {
		if (this.slAutoFocus !== false) {
			setTimeout(() => {
				(this.#el.nativeElement as HTMLElement).focus();
			}, 500);
		}
	}
}
